import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton
} from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";

const DeleteCompany = ({ companyCode, callback }) => {
  const [visible, setVisible] = useState(false);
  const deleteCompany = () => {
    setVisible(false);
    if (typeof callback === "function") {
      callback();
    }
  };
  return (
    <>
      <IconButton onClick={() => setVisible(true)}>
        <DeleteOutline />
      </IconButton>
      <Dialog open={visible}>
        <DialogTitle id="simple-dialog-title">
          Delete Company {companyCode}
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete the company {companyCode}? Doing so
          will remove all users assigned to that company and all of their data.
          This action is not reversible.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVisible(false)} color="primary">
            CANCEL
          </Button>
          <Button onClick={deleteCompany} color="secondary">
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteCompany;
