import React, { useState } from "react";
import {
  Container,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Grid,
  FormControl,
  TextField,
  Button,
  Divider,
  Box,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter, Link } from "react-router-dom";
import { compose } from "recompose";

import { useError } from "../ErrorProvider";
import { SignUpLink } from "../Signup";
import { withFirebase } from "../Firebase";
import PasswordResetLink from "../Passwordreset";
import * as ROUTES from "../../constants/routes";

import Header from "../CompanyHeader";
import { ResponsiveClasses } from "../StyledComponents";

const useStyles = makeStyles((theme) => ({
  link: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  signInText: {
    display: "inline",
    marginRight: "8px",
  },
}));

const SignInPage = () => {
  return (
    <Container fixed>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item sm={12} md={6}>
          <Card>
            <CardHeader title="Sign In" />
            <CardContent>
              <SignInForm />
              <center>
                <PasswordResetLink />
              </center>
            </CardContent>
            <Divider orientation="horizontal" variant="fullWidth" />
            <CardActions disableSpacing>
              <Box flexBasis="100%" textAlign="center">
                <SignUpLink />
              </Box>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

const INITIAL_STATE = {
  email: "",
  password: "",
};

const SignInFormBase = ({ firebase, history }) => {
  const [state, setState] = useState(INITIAL_STATE);
  const errorProvider = useError();

  const onSubmit = (event) => {
    const { email, password } = state;
    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((d) => {
        setState({ ...INITIAL_STATE });

        firebase
          .getRedirect()
          .then(({ data }) => {
            history.replace(data.redirect);
          })
          .catch((err) => {
            console.log(err);
            history.replace(ROUTES.USERDASHBOARD);
          });
      })
      .catch((error) => {
        errorProvider.showError(error.message);
      });
    event.preventDefault();
  };

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const isInvalid = state.password === "" || state.email === "";

  return (
    <form onSubmit={onSubmit}>
      <TextField
        label="Email"
        type="email"
        placeholder="Email"
        name="email"
        variant="outlined"
        value={state.email}
        onChange={onChange}
        fullWidth
      />
      <TextField
        label="Password"
        type="password"
        placeholder="Password"
        name="password"
        variant="outlined"
        value={state.password}
        onChange={onChange}
        fullWidth
      />
      <FormControl fullWidth>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isInvalid}
        >
          Sign In
        </Button>
      </FormControl>
    </form>
  );
};

const SignInLink = () => {
  const responsive = ResponsiveClasses();
  const classes = useStyles();
  return (
    <div>
      <Typography variant="body2" className={classes.signInText}>
        Already have an account?
      </Typography>
      <Link
        to={ROUTES.SIGNIN}
        replace={true}
        className={responsive.breakOnSmall}
      >
        Sign In
      </Link>
    </div>
  );
};

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

export default SignInPage;
export { SignInForm, SignInLink };
