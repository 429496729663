import React, { useState } from "react";
import { Drawer, ListItem, ListItemIcon, ListItemText, Divider, Hidden, AppBar, Toolbar, IconButton, Menu, Box, Tooltip } from "@material-ui/core";
import { PeopleOutline as PeopleOutlineIcon, AccountCircleOutlined, Menu as MenuIcon } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import SignOutButton from '../Signout';
import CompanyLogo from '../CompanyLogo';
import { AuthUserContext } from "../Session";

import * as ROUTES from "../../constants/routes";

const useStyles = makeStyles( (theme) => ({
      centerContent: {
        alignItems: "center",
        justifyContent: "center"
      },
      pullBottom: {
        position: "fixed",
        bottom: 0
      },
      bottomMargin: {
        marginBottom: "42px"
    }
   })
);

const SideItems = (props) => {
    return (
        <AuthUserContext.Consumer>
            {(authUser) => authUser && <SideItemsAuth authUser={authUser} {...props} />}
        </AuthUserContext.Consumer>
    )
}

const SideItemsAuth = ({ authUser, ...props}) => {
    const classes = useStyles();
    const [selection, setSelection] = useState(ROUTES.ADMINDASHBOARDHOME);
    const [email, setEmail] = useState(authUser.email);

    const onItemClick = (selectedKey,e) => { 
        setSelection(selectedKey);
        props.history.push(selectedKey);
        e.preventDefault();
    };

    return (
        <Drawer variant="permanent"
        anchor="left"
        >
            <ListItem className={classes.centerContent}>
                <Box margin="32px 0 42px">
                    <CompanyLogo size="sm" height="100"/>
                </Box>
            </ListItem>
            <Divider classes={{ root: classes.bottomMargin }} />
            <ListItem button key={ROUTES.ADMINDASHBOARDHOME} onClick={(e) => onItemClick(ROUTES.ADMINDASHBOARDHOME, e)} selected={ selection === ROUTES.ADMINDASHBOARDHOME }>
                <ListItemIcon><PeopleOutlineIcon/></ListItemIcon>
                <ListItemText>Companies</ListItemText>
            </ListItem>
            <ListItem className={classes.pullBottom}>
                <ListItemIcon>
                    <Tooltip title={email}>
                        <AccountCircleOutlined />
                    </Tooltip>
                </ListItemIcon>
                <ListItemText> <SignOutButton /> </ListItemText>
            </ListItem>
        </Drawer>
    );
  };

  const MenuItems = withRouter(SideItems);

const Side = props => {
    return (
        <Drawer variant="permanent" anchor="left">
            <MenuItems {...props} />
        </Drawer>
    );
  };

const Top = props => {
    const [open, setOpen] = useState();
    
    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton onClick={(evt) => setOpen(true)} color="inherit">
                    <MenuIcon />
                </IconButton>
                <h4>Smart Squirrel</h4>
                <Drawer
                    open={open}
                    anchor="left"
                    elevation={20}
                    onClose={() => setOpen(false)}
                >
                    <MenuItems {...props} onClick={() => setOpen(false)} />
                </Drawer>
            </Toolbar>
        </AppBar>
    )
}

const AdminSidebar = props => {
    return (
        <>
            <Hidden mdUp>
                <Top {...props} />
            </Hidden>
            <Hidden smDown>
                <Side {...props} />
            </Hidden>
        </>
    )    
}

//   const AdminSidebar = withRouter(Sidebar);
  export default AdminSidebar;