import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Container, Grid } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { withFirebase } from "../Firebase";
import { Overrides } from "../StyledComponents";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  center: {
	margin: '0 auto'
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const DashboardHelpBase = ({ firebase }) => {
	const classes = useStyles();
	const [questions, setQuestions] = useState([]);

	const styles = Overrides();

	useEffect(() => {
    firebase.faq().get().then((collection) => {
      let temp = []
      for(let i=0; i< collection.docs.length;i ++){
        temp.push(collection.docs[i].data());
       }
       temp.sort((a,b) => { return a.sequence - b.sequence})
       setQuestions(temp)
    }).catch((err) => {
      console.log(err);
    })
  }, [firebase]);

	return (  
		<div>
			<Grid container alignItems="center">
		  	  	<Grid item xs={6}>
		  	  		<h1>Help</h1>
		  	  	</Grid>
				<Grid item xs={6}>
					<Typography variant="body2" className={`${styles.italic} ${styles.textRight}`}>“Save like a champion today” - Steve Squirrel</Typography>
				</Grid>
	  	  	</Grid>
			<Container>
				<Grid container>
					<Grid item md={10} className={classes.center}>
						{questions.length > 0 && questions.map((question) => {
							return (<Accordion key={question.sequence}>
							<AccordionSummary expandIcon={<ExpandMore />}>
								<Typography className={classes.heading}>{question.title}</Typography>
							</AccordionSummary>	
							<AccordionDetails>
								{question.text}
							</AccordionDetails>	
						</Accordion>)		
						})}
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

const DashboardHelp = withFirebase(DashboardHelpBase);

export default DashboardHelp;