import React from "react";
import { SnackbarProvider, useSnackbar } from "notistack";

export const ErrorProvider = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export const useError = () => {
  const { enqueueSnackbar } = useSnackbar();
  return {
    showError: (message) => enqueueSnackbar(message, { variant: "error" }),
    showSuccess: (message) => enqueueSnackbar(message, { variant: "success" }),
  };
};
