import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import withAuthentication from "./withAuthentication";

//given a condition fucntion and optional redirect location this HOC is used to enforce users ability to view certain sections of the app.
const withAuthorization = (condition, redirect) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        (authUser) => {
          authUser.getIdTokenResult().then((idTokenResult) => {
            if (!condition({ ...authUser, claims: idTokenResult.claims })) {
              this.props.history.replace(redirect);
            }
          });
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  return compose(
    withRouter,
    withFirebase,
    withAuthentication
  )(WithAuthorization);
};

export default withAuthorization;
