import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.firestore();

    // **** Cloud functions ****
    this.getRedirect = app.functions().httpsCallable("getUserRedirect");
    this.exchangePublicToken = app
      .functions()
      .httpsCallable("exchangePlaidPublicToken");
    this.LinkToken = app.functions().httpsCallable("getLinkToken");
    this.UpdateLinkToken = app.functions().httpsCallable("getLinkUpdateToken");
    this.setUserAccount = app.functions().httpsCallable("setUserAccount");
    this.updateUserBalance = app.functions().httpsCallable("updateUserBalance");
    this.cleanupCompany = app.functions().httpsCallable("cleanupCompany");
    this.userPlayedGame = app.functions().httpsCallable("userPlayedGame");
    this.isUserWinner = app.functions().httpsCallable("isUserWinner");
    this.getCompanyUsers = app.functions().httpsCallable("getCompanyUsers");
    this.getCompanyStatistics = app
      .functions()
      .httpsCallable("getCompanyStatistics");
    this.getCompanyWinners = app.functions().httpsCallable("getCompanyWinners");
    this.deleteUser = app.functions().httpsCallable("deleteCompanyUser");
    this.userLinkToken = "";

    this.loading = false;
  }

  // **** Link API's ****
  getLinkToken = () => {
    return this.LinkToken()
      .then((d) => {
        return d.data.link_token;
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
      });
  };

  getUpdateLinkToken = () => {
    return this.UpdateLinkToken()
      .then((d) => {
        return d.data.link_token;
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
      });
  };

  // **** Auth API's ****

  verifyCompanyCode = (companyCode) => {
    this.loading = true;
    return this.db
      .collection("companies")
      .doc(`${companyCode}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {});
  };

  doCreateUserWithEmailAndPassword = (email, password) => {
    this.loading = true;
    return this.auth.setPersistence("session").then(() => {
      return this.auth
        .createUserWithEmailAndPassword(email, password)
        .then((authUser) => {
          return this.getLinkToken().then(() => {
            return authUser;
          });
        });
    });
  };

  doSignInWithEmailAndPassword = (email, password) => {
    this.loading = true;
    return this.auth.setPersistence("session").then((res) => {
      return this.auth
        .signInWithEmailAndPassword(email, password)
        .then((d) => {});
    });
  };

  doSignOut = () => this.auth.signOut();
  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);
  doUpdateEmail = (email) => this.auth.currentUser.updateEmail(email);

  handleResetPassword = (actionCode, newPassword) => {
    // Verify the password reset code is valid.
    return this.auth.verifyPasswordResetCode(actionCode).then((email) => {
      return this.auth
        .confirmPasswordReset(actionCode, newPassword)
        .then((resp) => {
          // Password reset has been confirmed and new password updated.
        });
    });
  };

  refreshUser = () => {
    if (this.auth.currentUser) {
      return this.auth.currentUser.getIdToken(true);
    } else {
      return Promise.resolve();
    }
  };

  // **** Info API's ****
  agreements = () => this.db.collection("Agreements");
  faq = () => this.db.collection("faq");

  // **** User API's ****
  user = (uid) => this.db.collection("users").doc(`${uid}`);

  clearAccountStatus = (uid) =>
    this.db.collection("users").doc(`${uid}`).update("accountStatus", "");

  // **** Account API's ****
  balances = (uid) => this.db.collection("balances").doc(`${uid}`);
  accounts = (uid) => this.db.collection("accounts").doc(`${uid}`);
}

export default Firebase;
