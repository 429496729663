import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, FormControl } from '@material-ui/core';
//import { makeStyles } from '@material-ui/core/styles';

import utils from  '../utils';

const PasswordDialog = ({ open, handleUpdate, handleCancel }) => {
	const [password1,setPassword1] = useState('');
	const [password2,setPassword2] = useState('');

  const cancel = (event) => {
    handleCancel();
    event.preventDefault();
  }

  const updatePassword = (event) => {
    handleUpdate(password1);
    event.preventDefault();
  }

  const resetState = () => {
    setPassword1('');
    setPassword2('');
  }

  const isValid = () => {
    return (password1 == "" || password2 == "") || password1 === password2 && utils.validatePassword(password1);
  }

	return (
		<Dialog open={open} TransitionProps={{ onEnter: resetState }} aria-labelledby="form-dialog-title" maxWidth="xs">
        <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your new password.
          </DialogContentText>
          <FormControl fullWidth>
          <TextField
            autoFocus
            value={password1}
            margin="dense"
            id="password1"
            label="New password"
            type="password"
            onChange={ event => setPassword1(event.target.value) } 
            fullWidth
          />
          </FormControl>
          <FormControl fullWidth>
          <TextField 
            value={password2}
            margin="dense"
            id="password2"
            label="Confirm password"
            type="password"
            onChange={ event => setPassword2(event.target.value) } 
            fullWidth
            error={!isValid()}
            helperText={!isValid() ? "Your passwords do not match." : ""}
          />
          </FormControl>

        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color="primary">
            Cancel
          </Button>
          <Button onClick={updatePassword} color="primary" disabled={!isValid() || password1 == "" || password2 == ""}> 
            Update
          </Button>
        </DialogActions>
      </Dialog>
	);
}

export default PasswordDialog;