import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import Link from "../Link";

const reauthDialog = ({ open, close }) => {
  const success = (event) => {
    close(true);
    if (event) {
      event.preventDefault();
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      disableEnforceFocus={true}
    >
      <DialogTitle id="form-dialog-title">
        Reauthenticate Bank Account
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          It looks like your banking credentials have expired. Click to enter
          them again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Link linkText="Enter Credentials" mode="UPDATE" onSuccess={success} />
      </DialogActions>
    </Dialog>
  );
};

export default reauthDialog;
