import React from "react";
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Button
} from "@material-ui/core";


export default function Confirm({ title, confirmText, open, confirmAction, cancelAction, children}) {

    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button color="default" onClick={cancelAction}>Cancel</Button>
                <Button color="secondary" variant="contained" onClick={confirmAction}>{confirmText}</Button>
            </DialogActions>
        </Dialog>

    )
}