import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  TextField,
  Button,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import LoadingOverlay from "react-loading-overlay";
import { useError } from "../ErrorProvider";
import utils from "../utils";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  topMargin: {
    marginTop: "36px",
  },
}));

const SignUpPage = () => (
  <div>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  companyCode: "",
  termsOfService: false,
};

const SignUpFormBase = ({ firebase, history }) => {
  const [state, setState] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [passwordOneError, setPasswordOneError] = useState(false);
  const [passwordTwoError, setPasswordTwoError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const errorProvider = useError();

  const classes = useStyles();

  const onSubmit = (event) => {
    const { username, email, passwordOne, companyCode } = state;
    setLoading(true);

    firebase
      .verifyCompanyCode(companyCode)
      .then((res) => {
        if (res) {
          return firebase
            .doCreateUserWithEmailAndPassword(email, passwordOne)
            .then((authUser) => {
              return firebase
                .user(authUser.user.uid)
                .set(
                  { username, email, companyCode, drawingStatus: "notSetup" },
                  { merge: true }
                );
            })
            .then(() => {
              setState({ ...INITIAL_STATE });
              firebase
                .refreshUser()
                .then(() => {
                  firebase
                    .getRedirect()
                    .then(({ data }) => {
                      if (data.redirect === ROUTES.USERDASHBOARD)
                        history.replace(ROUTES.BANKSIGNUP);
                      else {
                        history.replace(data.redirect);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      history.replace(ROUTES.BANKSIGNUP);
                    });
                })
                .catch((err) => {
                  console.log(err);
                });
            });
        } else {
          errorProvider.showError(
            "Invalid Company Code. Make sure you entered the correct code."
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        errorProvider.showError(error.message);
        setLoading(false);
      });

    event.preventDefault();
  };

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const validatePasswords = (event) => {
    setPasswordMismatch(state.passwordOne !== state.passwordTwo);
    setPasswordOneError(
      state.passwordOne.length > 0 && !utils.validatePassword(state.passwordOne)
    );
    setPasswordTwoError(
      state.passwordTwo.length > 0 && !utils.validatePassword(state.passwordTwo)
    );
  };

  const tosChange = (event) => {
    setState({ ...state, termsOfService: event.target.checked });
  };

  const isInvalid =
    state.passwordOne !== state.passwordTwo ||
    !utils.validatePassword(state.passwordOne) ||
    state.email === "" ||
    !utils.validateEmailAddress(state.email) ||
    state.username === "" ||
    state.companyCode === "" ||
    !!!state.termsOfService;

  return (
    <LoadingOverlay active={loading} spinner>
      <form onSubmit={onSubmit}>
        <TextField
          label="Name"
          placeholder="Name"
          name="username"
          variant="outlined"
          value={state.username}
          onChange={onChange}
          fullWidth
        />
        <TextField
          label="Email"
          placeholder="Email"
          name="email"
          variant="outlined"
          type="email"
          value={state.email}
          onChange={onChange}
          onBlur={() => {
            setEmailError(!utils.validateEmailAddress(state.email));
          }}
          className="signUpText"
          error={emailError}
          fullWidth
        />
        <TextField
          label="Password"
          type="password"
          placeholder="Password"
          name="passwordOne"
          variant="outlined"
          value={state.passwordOne}
          onChange={onChange}
          onBlur={() => validatePasswords()}
          className="signUpText"
          error={passwordOneError}
          fullWidth
        />
        <Tooltip
          title="Passwords don't match"
          open={passwordMismatch}
          placement="left-start"
        >
          <TextField
            label="Confirm Password"
            type="password"
            placeholder="Confirm Password"
            name="passwordTwo"
            variant="outlined"
            value={state.passwordTwo}
            onChange={onChange}
            onBlur={() => validatePasswords()}
            error={passwordTwoError}
            className="signUpText"
            helperText="Password must be at least 8 characters long"
            fullWidth
          />
        </Tooltip>
        <TextField
          label="Company Code"
          placeholder="Company Code"
          name="companyCode"
          variant="outlined"
          value={state.companyCode}
          onChange={onChange}
          className="signUpText"
          fullWidth
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.termsOfService}
              onChange={tosChange}
              name="termsOfService"
              color="primary"
            />
          }
          label={
            <span>
              {" "}
              I have read and agree to the{" "}
              <Link target="_blank" to={ROUTES.TOS}>
                Terms of Service
              </Link>
            </span>
          }
        />
        <FormControl fullWidth>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isInvalid}
            className={classes.topMargin}
          >
            CREATE MY ACCOUNT
          </Button>
        </FormControl>
      </form>
    </LoadingOverlay>
  );
};

const SignUpLink = () => (
  <span>
    New to Smart Squirrel?{" "}
    <Link to={ROUTES.HOME} replace={true}>
      Create An Account
    </Link>
  </span>
);

const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase);

export default SignUpPage;
export { SignUpForm, SignUpLink };
