import { Button } from "@material-ui/core";
import { styled, makeStyles } from "@material-ui/core/styles";

export const ButtonGreen = styled(Button)({
  backgroundColor: "#008F6D",
  color: "white",
});

export const CompanyCodeDisplay = styled("div")({
  fontSize: "14px",
  fontStyle: "italic",
  color: "#585D5F",
  lineHeight: "3.75rem",
  textAlign: "right"
});

export const LoadingBackground = styled("div")({
  minHeight: "600px"
})

export const Overrides = makeStyles((theme) => ({
  textRight: {
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left"
    }
  },
  italic: {
    fontStyle: "italic"
  },
  failedIcon: {
    color: "#694D75",
    fontSize: "2em"
  },
  failedBorder: {
    borderColor: "#694D75"    
  },
  successBorder: {
    border: "1px solid #008F6D",
  },
  successIcon: {
    color: "#008F6D",
    fontSize: "2em"
  }
}))

export const ResponsiveClasses = makeStyles((theme) => ({
  breakOnSmall: {
    [theme.breakpoints.down("sm")]:{
      display: "block"
    }
  },
  mainContent: {
    [theme.breakpoints.up("sm")]: {
      padding: "24px 20px 0 216px"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "24px 20px 16px"
    }
  }
}));