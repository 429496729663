import React from "react";
import { Container } from "@material-ui/core";

import { Switch, Route } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

import { AuthUserContext, withAuthorization } from "../Session";
import AdminCompanies from "./AdminCompanies";
import AdminSidebar from "./AdminSidebar";
import { ResponsiveClasses } from "../StyledComponents";

const AdminDashboard = (props) => (
  <AuthUserContext.Consumer>
    {(authUser) => <AdminDashboardAuth authUser={authUser} {...props} />}
  </AuthUserContext.Consumer>
);

const AdminDashboardAuth = () => {
  const responsive = ResponsiveClasses();
  return (
    <>
      <AdminSidebar />
      <main className={responsive.mainContent}>
        <Switch>
          <Route path={ROUTES.ADMINDASHBOARDHOME} component={AdminCompanies} />
          <Route
            exact
            path={ROUTES.ADMINDASHBOARD}
            component={AdminCompanies}
          />
        </Switch>
      </main>
    </>
  );
};

const condition = (authUser) => {
  return !!authUser.claims && !!authUser.claims.superUser;
};

export default withAuthorization(
  condition,
  ROUTES.USERDASHBOARD
)(AdminDashboard);
