import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Divider,
  TextField,
  Box,
  Button,
  Radio,
  Container,
  Typography
} from "@material-ui/core";
import CurrencyFormat from "react-currency-format";
import { AuthUserContext } from "../Session";
import LoadingOverlay from "react-loading-overlay";

import { withFirebase } from "../Firebase";
import PasswordDialog from "./passwordDialog";
import LinkWrapper from "../Link";
import ReauthModal from "./reauthModal";
import { useError } from "../ErrorProvider";
import { Overrides } from "../StyledComponents";

const DashboardSettings = (props) => (
  <AuthUserContext.Consumer>
    {(authUser) => <DashboardSettingsBase authUser={authUser} {...props} />}
  </AuthUserContext.Consumer>
);
const DashboardSettingsBase = ({ firebase, authUser }) => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [reauthModalOpen, setReauthModalOpen] = useState(false);
  const [accounts, setAccounts] = useState({});
  const [selectedAccount, setSelectedAccount] = useState("");
  const [currentUserAccount, setCurrentUserAccount] = useState("");
  const { showError } = useError();
  const styles = Overrides();

  const closeReauthModal = (success) => {
    if (success) {
      firebase.clearAccountStatus(authUser.uid);
    }
    setReauthModalOpen(false);
  };

  const loadData = useCallback(() => {
    setLoading(true);
    Promise.allSettled([
      firebase.user(authUser.uid).get(),
      firebase.accounts(authUser.uid).get(),
    ]).then((val) => {
      let user = val[0].value.data();
      let accounts = val[1].value ? val[1].value.data() : {};

      setEmail(user.email);
      setUsername(user.username);
      setSelectedAccount(user.account?.account_id);
      setCurrentUserAccount(user.account?.account_id);
      setAccounts(accounts);

      if (user.accountStatus === "LoginExpired") {
        setReauthModalOpen(true);
      }

      setLoading(false);
    });
  }, [firebase, authUser.uid]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const updateProfile = (event) => {
    setLoading(true);
    Promise.allSettled([
      firebase.doUpdateEmail(email),
      firebase.user(authUser.uid).update("username", username),
    ]).then((d) => {
      setLoading(false);
    });

    event.preventDefault();
  };

  const openPasswordDialog = (event) => {
    setPasswordDialogOpen(true);

    event.preventDefault();
  };

  const handlePasswordCancel = () => {
    setPasswordDialogOpen(false);
  };

  const handlePasswordUpdate = (pass) => {
    console.log(pass);
    setPasswordDialogOpen(false);
    setLoading(true);
    firebase
      .doPasswordUpdate(pass)
      .then((d) => {
        setLoading(false);
      })
      .catch((err) => {
        if (err.code === "auth/requires-recent-login") {
        } else {
          console.log(`error updating password ${err.message}`);
        }
        showError(`Error updating users password: ${JSON.stringify(err)}`);
        setLoading(false);
      });
  };

  const onAccountChange = (event) => {
    setSelectedAccount(event.target.value);
  };

  const bankChanged = () => {
    console.log("changed bank!");
  };

  const updateUserAccount = () => {
    setLoading(true);
    firebase
      .setUserAccount({ account_id: selectedAccount })
      .then(() => {
        console.log("account updated");
      })
      .catch((err) => {
        showError(`Error updating users account: ${JSON.stringify(err)}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        className="dashboardContainer"
      >
        <Grid item sm={6}>
          <h1>Settings</h1>
        </Grid>
        <Grid item sm={6}>
          <Typography variant="body2" className={`${styles.italic} ${styles.textRight}`}>“Save like a champion today” - Steve Squirrel</Typography>
        </Grid>
      </Grid>
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item sm={12} md={6}>
            <LoadingOverlay active={loading} spinner>
              <Card>
                <CardContent>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                  >
                    <Grid className="cardHeader" item xs={12}>
                      Profile
                    </Grid>
                    <Grid item xs={12}>
                      <form onSubmit={updateProfile}>
                        <TextField
                          label="Name"
                          placeholder="Name"
                          name="username"
                          variant="outlined"
                          value={username}
                          onChange={(event) => setUsername(event.target.value)}
                          fullWidth
                        />
                        <TextField
                          label="Email"
                          placeholder="Email"
                          name="email"
                          variant="outlined"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                          fullWidth
                        />
                        <Button
                          fullWidth={true}
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          UPDATE PROFILE
                        </Button>
                      </form>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider orientation="horizontal" variant="fullWidth" />
                <CardActions>
                  <Box flexBasis="100%" textAlign="center">
                    <a href="" onClick={openPasswordDialog}>
                      Change Your Password{" "}
                    </a>
                  </Box>
                </CardActions>
              </Card>
            </LoadingOverlay>
          </Grid>
          <Grid item sm={12} md={6}>
            <LoadingOverlay active={loading} spinner>
              <Card>
                <CardContent>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                  >
                    <Grid className="cardHeader" item xs={12}>
                      Banking
                    </Grid>
                  </Grid>
                </CardContent>
                {Object.keys(accounts).length === 0 ? (
                  <CardContent>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="stretch"
                      spacing={3}
                    >
                      <Grid item xs={12}>
                        No other accounts available. Please use the "Change Your
                        Bank" link to add accounts.
                      </Grid>
                    </Grid>
                  </CardContent>
                ) : (
                  <CardContent>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="stretch"
                      spacing={3}
                    >
                      <Grid item xs={12}>
                        What account do you want to use?
                      </Grid>
                    </Grid>
                  </CardContent>
                )}
                {Object.keys(accounts).map((item, i, arr) => (
                  <React.Fragment key={i}>
                    <CardContent className="accountItem">
                      <Grid container alignItems="center">
                        <Grid item xs={2}>
                          <Radio
                            checked={selectedAccount === item}
                            onChange={onAccountChange}
                            value={item}
                            name="radio-account"
                            inputProps={{ "aria-label": accounts[item].name }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <span>
                            {accounts[item].name} - {accounts[item].mask}
                          </span>
                        </Grid>
                        <Grid item xs={4} className="cardRightJustify">
                          <CurrencyFormat
                            value={accounts[item].balances.current}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    {i === arr.length - 1 ? null : (
                      <Divider orientation="horizontal" variant="middle" />
                    )}
                  </React.Fragment>
                ))}
                {Object.keys(accounts).length > 0 && (
                  <CardContent>
                    <Button
                      fullWidth={true}
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="signUpButton"
                      onClick={updateUserAccount}
                      disabled={currentUserAccount === selectedAccount}
                    >
                      UPDATE ACCOUNT
                    </Button>
                  </CardContent>
                )}

                <Divider orientation="horizontal" variant="fullWidth" />
                <CardActions>
                  <Box flexBasis="100%" textAlign="center">
                    <LinkWrapper
                      onSuccess={bankChanged}
                      linkType={"anchor"}
                      linkText={"Change Your Bank"}
                    ></LinkWrapper>
                  </Box>
                </CardActions>
              </Card>
            </LoadingOverlay>
          </Grid>
        </Grid>
        <PasswordDialog
          open={passwordDialogOpen}
          handleCancel={handlePasswordCancel}
          handleUpdate={handlePasswordUpdate}
        />
        <ReauthModal open={reauthModalOpen} close={closeReauthModal} />
      </Container>
    </div>
  );
};

export default withFirebase(DashboardSettings);
