import React from "react";
import { AuthUserContext, withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";
import CompanyHeader from "../CompanyHeader";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import LinkWrapper from "../Link";
import * as ROUTES from "../../constants/routes";

const BankSignUp = (props) => (
  <AuthUserContext.Consumer>
    {(authUser) => <Bank authUser={authUser} {...props} />}
  </AuthUserContext.Consumer>
);

const Bank = ({ history, location }) => {
  const plaidSuccess = () => {
    history.replace(ROUTES.ACCOUNTSELECT);
  };
  const mode =
    location.pathname.indexOf(ROUTES.OAUTH) >= 0 ? "OAUTH" : "CREATE";

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={3}
    >
      <Grid item xs={12}>
        <CompanyHeader></CompanyHeader>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs sm></Grid>
        <Grid item xs={8} sm={4}>
          <Alert icon={false} variant="filled" severity="success">
            Account Created!
          </Alert>
        </Grid>
        <Grid item xs sm></Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs sm></Grid>
        <Grid item xs={8} sm={4}>
          <Card>
            <CardContent style={{ fontSize: "24px" }}>
              Connect Account
            </CardContent>
            <CardContent>
              <Typography variant="body2">
                Next let’s connect to your savings account. This will let you
                participate in the sweepstakes.
              </Typography>
            </CardContent>
            <CardContent>
              <LinkWrapper onSuccess={plaidSuccess} mode={mode}></LinkWrapper>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs sm></Grid>
      </Grid>
    </Grid>
  );
};

const condition = (authUser) => !!authUser;

export default withFirebase(withAuthorization(condition)(BankSignUp));
