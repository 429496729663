import React, {useState} from "react";
import {Dialog, IconButton, DialogTitle, DialogContent, DialogContentText, TextField, Button, DialogActions} from "@material-ui/core";
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import {withFirebase} from "../Firebase";
import { useError } from "../ErrorProvider";


const AddCompanyDialog = withFirebase(({firebase, onSuccess}) => {
    const [open,setOpen] = useState(false);
    const [companyCode,setCompanyCode] = useState('');
    const [contactName,setContactName] = useState('');
    const [HREmail,setHREmail] = useState('');
    const [companyName,setCompanyName] = useState('');
    const { showError } = useError();

    const submit = () => {
        firebase.company(companyCode).set({
            CompanyName: companyName,
            ContactEmail: HREmail,
            ContactName: contactName
        }).then(d=>{
            if(typeof onSuccess === "function") {
                onSuccess();
            }
            setOpen(false);
        }).catch(err => {
            showError(`Error creating company: ${err}`);
        });
    }

    const valid = () => companyName && companyCode && contactName && HREmail;

    return (<>
        <IconButton onClick={() => setOpen(true)}>
            <AddBoxOutlinedIcon />
        </IconButton>
    <Dialog open={open} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Add Company</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Enter your new password.
      </DialogContentText>
      <TextField
        autoFocus
        value={companyCode}
        margin="dense"
        id="companyCode"
        label="Company Code"
        type="text"
        onChange={ event => setCompanyCode(event.target.value) } 
        fullWidth
      />
      <TextField 
        value={contactName}
        margin="dense"
        id="ContactName"
        label="Contact Name"
        type="text"
        onChange={ event => setContactName(event.target.value) } 
        fullWidth
      />
      <TextField 
        value={HREmail}
        margin="dense"
        id="HREmail"
        label="HR Email"
        type="text"
        onChange={ event => setHREmail(event.target.value) } 
        fullWidth
      />
      <TextField 
      value={companyName}
      margin="dense"
      id="CompanyName"
      label="Company Name"
      type="text"
      onChange={ event => setCompanyName(event.target.value) } 
      fullWidth
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setOpen(false)} color="primary">
        Cancel
      </Button>
      <Button onClick={submit} color="primary" disabled={!valid()}> 
        Add Company
      </Button>
    </DialogActions>
  </Dialog></>)
});

export default AddCompanyDialog;