import React from "react";
/*import { Container,Col,Row,Button,Nav,Navbar } from 'react-bootstrap';*/
import { BrowserRouter as Router, Route } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";

import Home from "../Home";
import About from "../About";
import SignUpPage from "../Signup";
import SignInPage from "../Signin";
import UserDashboard from "../UserDashboard";
import AdminDashboard from "../AdminDashboard";
import HRDashboard from "../HRDashboard";
import BankSignUp from "../BankSignUp";
import AccountSelect from "../AccountSelect";
import TermsOfService from "../TOS";
import { ErrorProvider } from "../ErrorProvider";
import SVGLogo from "../../images/sqrl-02.svg";
import { PasswordResetPage } from "../Passwordreset";
import AuthManagmenet from "../Auth";
import theme from "../StyledComponents/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  app: {
    backgroundImage: `url(${SVGLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0% 100%",
    backgroundSize: "33%",
    height: "100%",
  },
}));

const App = () => {
  const classes = useStyles();

  return (
    <ErrorProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
            integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
            crossOrigin="anonymous"
          />
          <CssBaseline>
            <div className={classes.app}>
              <Route path={ROUTES.SIGNUP} component={SignUpPage} />
              <Route path={ROUTES.SIGNIN} component={SignInPage} />
              <Route path={ROUTES.ABOUT} component={About} />
              <Route path={ROUTES.HOME} component={Home} />
              <Route path={ROUTES.USERDASHBOARD} component={UserDashboard} />
              <Route path={ROUTES.ADMINDASHBOARD} component={AdminDashboard} />
              <Route path={ROUTES.HRDASHBOARD} component={HRDashboard} />
              <Route path={ROUTES.BANKSIGNUP} component={BankSignUp} />
              <Route path={ROUTES.OAUTH} component={BankSignUp} />
              <Route path={ROUTES.ACCOUNTSELECT} component={AccountSelect} />
              <Route path={ROUTES.TOS} component={TermsOfService} />
              <Route
                path={ROUTES.PASSWORDRESET}
                component={PasswordResetPage}
              />
              <Route path={ROUTES.AUTHMANAGEMENT} component={AuthManagmenet} />
              <Route exact path={ROUTES.LANDING} component={Home} />
            </div>
          </CssBaseline>
        </Router>
      </ThemeProvider>
    </ErrorProvider>
  );
};

export default withAuthentication(App);
