import React, { useState } from "react";
import {
  Card,
  Grid,
  Typography,
  Button,
  CardContent,
  Box,
} from "@material-ui/core";
import { styled } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Wheel from "../Wheel";
import winner from "../../audio/winner.mp3";
import nonwinner from "../../audio/nonwinner.mp3";

const winnerAudio = new Audio(winner);
const nonwinnerAudio = new Audio(nonwinner);

const TypographyOrange = styled(Typography)({
  color: "#F37748",
});

const pageStyles = makeStyles({
  gameResult: {
    margin: "24px 0 12px",
  },
  gameResultDescription: {
    marginBottom: "54px",
  },
  encouragement: {
    marginBottom: "12px",
  },
});

const DashboardGame = ({ isWinner, callback }) => {
  const options = [
    "",
    "",
    "Winner",
    "",
    "",
    "",
    "Winner",
    "",
    "",
    "",
    "Winner",
    "",
    "",
    "",
    "Winner",
    "",
  ];

  const styles = pageStyles();

  const [view, setView] = useState("game");
  return (
    <Card>
      <CardContent component="center">
        {view === "game" && (
          <Grid
            container
            spacing={3}
            alignItems="center"
            direction="column"
          >
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                Spin the wheel below for a chance to win a prize.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Wheel
                items={options}
                isWinner={isWinner}
                onSelectItem={() => {
                  setView("results");
                  if (isWinner) {
                    winnerAudio.play();
                  } else {
                    nonwinnerAudio.play();
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              Click the wheel to Spin.
            </Grid>
          </Grid>
        )}
        {view === "results" && (
          <Box
            display="flex"
            flexDirection="column"
            minHeight="360px"
          >
            <Box flex="1 1">
              <Typography variant="h3" className={styles.gameResult}>
                {isWinner ? "Winner! Winner!" : "Awww Nuts."}
              </Typography>
              <Typography
                variant="body2"
                className={styles.gameResultDescription}
              >
                {isWinner ? "Acorn Dinner" : "Not A Winning Spin"}
              </Typography>
              <Typography variant="h5" className={styles.encouragement}>
                {isWinner
                  ? "All that saving paid off! Your company Smart Squirrel contact will be reaching out to you about your prize."
                  : "But that’s okay! Keep saving for a chance to play again next week!"}
              </Typography>
              <TypographyOrange variant="h5">
                Keep saving for a chance to {isWinner ? "win" : "play"} again.
              </TypographyOrange>
            </Box>
            <Box flex="0 0 80px" padding="24px 0">
              <Button
                style={{ width: "296px" }}
                variant="contained"
                color="primary"
                onClick={callback}
              >
                Done
              </Button>
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default DashboardGame;
