import React from "react";
import { Link } from "@material-ui/core";
import { withFirebase } from "../Firebase";
import { useHistory } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

const SignOutButtonBase = ({ firebase }) => {
  const history = useHistory();
  const signOut = () => {
    firebase.doSignOut().then(() => {
      history.replace(ROUTES.SIGNIN);
    });
  };

  return (
    <Link href="#" onClick={signOut}>Sign Out</Link>
  );
};
const SignOutButton = withFirebase(SignOutButtonBase);

export default SignOutButton;
