import React, { useCallback, useState, useEffect } from "react";
import { withFirebase } from "../Firebase";
import { usePlaidLink } from "react-plaid-link";
import { Button } from "@material-ui/core";

const Link = withFirebase(
  ({ firebase, onSuccess, linkType, linkText, mode }) => {
    const display = linkType ?? "button"; // button or anchor
    const text = linkText ?? "CONNECT";
    const linkMode = mode ?? "CREATE";
    /* we need to get a token before we can use the link flow*/
    const [token, setToken] = useState(null);
    useEffect(() => {
      if (linkMode === "CREATE") {
        firebase.getLinkToken().then((d) => {
          localStorage.setItem("linkToken", d);
          setToken(d);
        });
      } else if (linkMode === "UPDATE") {
        firebase.getUpdateLinkToken().then((d) => {
          localStorage.setItem("linkToken", d);
          setToken(d);
        });
      } else if (linkMode === "OAUTH") {
        setToken(localStorage.getItem("linkToken"));
      }
    }, [firebase, linkMode]);

    return (
      <span>
        {token && (
          <PlaidLinkButton
            onSuccess={onSuccess}
            token={token}
            firebase={firebase}
            displayStyle={display}
            text={text}
            linkMode={linkMode}
          ></PlaidLinkButton>
        )}
      </span>
    );
  }
);

const PlaidLinkButton = ({
  text,
  firebase,
  onSuccess,
  token,
  displayStyle,
  linkMode,
}) => {
  const onSuccessCallback = useCallback(
    (public_token, metadata) => {
      // send  public token to server
      if (linkMode === "CREATE") {
        firebase
          .exchangePublicToken({ public_token: public_token })
          .then(() => {
            if (typeof onSuccess === "function") {
              onSuccess();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (linkMode === "UPDATE") {
        if (typeof onSuccess === "function") {
          onSuccess();
        }
      }
    },
    [firebase, onSuccess]
  );

  const onEventCallback = useCallback(
    (eventName, metadata) => console.log("onEvent", eventName, metadata),
    []
  );

  const onExitCallback = useCallback(
    (err, metadata) => console.log("onExit", err, metadata),
    []
  );
  const config = {
    token: token,
    onSuccess: onSuccessCallback,
    onEvent: onEventCallback,
    onExit: onExitCallback,
  };

  const { open, ready, error } = usePlaidLink(config);

  const handleClick = (evt) => {
    evt.preventDefault();
    open();
  };

  if (displayStyle === "button")
    return (
      <Button
        onClick={handleClick}
        disabled={!ready || error}
        color="primary"
        variant="outlined"
        fullWidth
      >
        {text}
      </Button>
    );
  else
    return (
      <a href="" onClick={handleClick} disabled={!ready || error}>
        {text}
      </a>
    );
};
export default Link;
