import React, { useState } from "react";

import { Switch, Route } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

import { AuthUserContext, withAuthorization } from "../Session";
import Sidebar from "../Sidebar";
import DashboardHome from "../DashboardHome";
import DashboardSettings from "../DashboardSettings";
import DashboardHelp from "../DashboardHelp";
import { ResponsiveClasses } from "../StyledComponents";
import { ListItem, ListItemIcon, ListItemText, Divider, Box, Tooltip } from "@material-ui/core";
import { Speed, HelpOutline, Settings, AccountCircleOutlined } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import CompanyLogo from '../CompanyLogo';
import SignOutButton from '../Signout';

const useStyles = makeStyles( (theme) => ({
  centerContent: {
    alignItems: "center",
    justifyContent: "center"
  },
  pullBottom: {
    position: "fixed",
    bottom: 0
  },
  bottomMargin: {
      marginBottom: "42px"
  }
})
);

const UserDashboard = (props) => (
  <AuthUserContext.Consumer>
    {(authUser) => <UserDashboardAuth authUser={authUser} {...props} />}
  </AuthUserContext.Consumer>
);

const UserDashboardAuth = ({ authUser, ...props }) => {
  const responsive = ResponsiveClasses();
  const classes = useStyles();
  const [selection, setSelection] = useState(ROUTES.DASHBOARDHOME);
  const [email, setEmail] = useState(authUser.email);
  const [open, setOpen] = useState(false);

  const onItemClick = (selectedKey,e) => { 
      console.log(selection);
      setSelection(selectedKey);
      props.history.push(selectedKey);
      setOpen(false);
  };
  
  return (
    <>
      <Sidebar authUser={authUser} setOpen={setOpen} open={open}>
        <ListItem className={classes.centerContent}>
            <Box margin="32px 0 42px">
                <CompanyLogo size="sm" height="100"/>
            </Box>
        </ListItem>
        <Divider classes={{ root: classes.bottomMargin }} />
        <ListItem button key={ROUTES.DASHBOARDHOME} onClick={(e) => onItemClick(ROUTES.DASHBOARDHOME, e)} selected={ selection == ROUTES.DASHBOARDHOME }>
            <ListItemIcon><Speed/></ListItemIcon>
            <ListItemText>My Dashboard</ListItemText>
        </ListItem>
        <ListItem button key={ROUTES.DASHBOARDSETTINGS} onClick={(e) => onItemClick(ROUTES.DASHBOARDSETTINGS, e)} selected={ selection == ROUTES.DASHBOARDSETTINGS } >
            <ListItemIcon><Settings/></ListItemIcon>
            <ListItemText>Settings</ListItemText>
        </ListItem>
        <ListItem button key={ROUTES.DASHBOARDHELP} onClick={(e) => onItemClick(ROUTES.DASHBOARDHELP, e)} selected={ selection == ROUTES.DASHBOARDHELP }>
            <ListItemIcon><HelpOutline/></ListItemIcon>
            <ListItemText>Help</ListItemText>
        </ListItem>
        <ListItem className={classes.pullBottom}>
            <ListItemIcon>
                <Tooltip title={email}>
                    <AccountCircleOutlined />
                </Tooltip>
            </ListItemIcon>
            <ListItemText><SignOutButton /></ListItemText>
        </ListItem>
      </Sidebar>
      <main className={responsive.mainContent}>
        <Switch>
          <Route path={ROUTES.DASHBOARDHOME} component={DashboardHome} />
          <Route
            path={ROUTES.DASHBOARDSETTINGS}
            component={DashboardSettings}
          />
          <Route path={ROUTES.DASHBOARDHELP} component={DashboardHelp} />
          <Route
            exact
            path={ROUTES.USERDASHBOARD}
            component={DashboardHome}
          />
        </Switch>
      </main>
    </>
  );
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(UserDashboard);
