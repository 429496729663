import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, Typography, Divider } from "@material-ui/core";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import logo from "../../images/logo.png";
import { Overrides } from "../StyledComponents";

const MonthStyles = makeStyles({
    item: {
        flex: "1 1 100%",
        padding: "4px",
        textAlign: "center"
    },
    lastItem: {
        flex: "1 1 100%",
        padding: "4px",
        textAlign: "center",
        borderTop: "1px solid #ccc"
    }
})

export default function MonthSummary({ month, numWeeks, numericMonth }) {
    const styles = MonthStyles();

    const classes = Overrides();
    const thisMonth = (new Date()).getMonth();

    const getMonthName = (month) => {
        return new Intl.DateTimeFormat("en-US", { month: "long" }).format(
            new Date().setMonth(month)
        );
    };

    var currMonth = getMonthName(thisMonth);
    var isCurrentMonth = month == currMonth;
    var isSuccessfulMonth = numWeeks > 0;
    var isFutureMonth = numericMonth > thisMonth;
    var isFailedMonth = numWeeks == 0 && !isCurrentMonth && !isFutureMonth;
    
    return (
        <Paper 
            elevation={isCurrentMonth ? 5 : 0} 
            variant={isCurrentMonth ? "elevation" : "outlined"} 
            className={isFailedMonth ? classes.failedBorder : isSuccessfulMonth ? classes.successBorder : null}>
            <Box display="flex" flexDirection="column">
                <div className={styles.item}>
                    <Typography variant="h5">{month}</Typography>
                </div>
                <div className={styles.item}>
                    { (isCurrentMonth || isFutureMonth) && !isSuccessfulMonth &&
                        <img src={logo} height="32" alt="" />
                    }
                    { isFailedMonth && 
                        <SentimentVeryDissatisfiedIcon className={classes.failedIcon} />
                    }
                    { isSuccessfulMonth &&
                        <SentimentSatisfiedOutlinedIcon className={classes.successIcon} />
                    }
                </div>
                <div className={styles.item}>
                    <Typography variant="body2">
                        {isCurrentMonth || numWeeks > 0 ? "Keep Saving!" : "Next Time!"}
                    </Typography>
                </div>
                <Divider />
                <div className={styles.lastItem}>
                    <strong>{numWeeks}</strong> Weeks Saved
                </div>
            </Box>
        </Paper>
    )
}