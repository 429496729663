import createTheme from "@material-ui/core/styles/createTheme";
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({});

const palette = {
  primary: {
    main: "#2682B9",
    dark: "#008F6D"
  },
  secondary: {
    main: "#F37748"
  },
  warning: {
    main: "#694D75"
  }
}


const theme = createTheme({
  palette: palette,
  typography: {
    h2: {
      fontWeight: 400,
      fontFamily: "Open Sans, sans-serif"
    },
    h4: {
      fontSize: "2.125rem",
      [breakpoints.down("sm")]: {
        fontSize: "1.75rem"
      }
    },
    h5: {
      fontFamily: "Open Sans, sans-serif",
      fontSize: "18px"
    },
    subtitle1: {
      fontFamily: "Open Sans, sans-serif",
      textAlign: 'center',
      fontSize: '24px',
      letterSpacing: '0px',
      color: '#585D5F',
      marginBottom: '48px',
      opacity: 1,
      [breakpoints.down('sm')]: {
          fontSize: '20px'
      }
    },
    subtitle2: {
      fontFamily: "Open Sans, sans-serif",
      fontStyle: "italic",
      fontSize: "14px"
    },
    body2: {
      fontFamily: "Open Sans, sans-serif",
      fontSize: "14px",
      color: "#585D5F"
    }
  },
  overrides: {
    MuiLink: {
      root: {
        color: palette.secondary.main
      }
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: palette.primary.dark
      },
      outlinedPrimary: {
        borderColor: palette.primary.dark,
        backgroundColor: "#fff",
        color: palette.primary.dark,
        "&:hover":{
          border: `1px solid ${palette.primary.dark}`
        }
      },
      containedSecondary: {
        color: "#fff"
      }
    },
    MuiFormControl: {
      root: {
        marginBottom: "16px"
      }
    },
    MuiCardActions: {
      root: {
        padding: "16px"
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#008F6D"
      }
    },
    MuiCardContent: {
      root: {
        paddingBottom: "42px"
      }
    },
    MuiListItem: {
      root: {
        "&.Mui-selected": {
          backgroundColor: "inherit",
          color: "#1E4E77",
          "& .MuiListItemIcon-root": {
            color: "#1E4E77"
          }
        }
      }
    },
    MuiDrawer: {
      root: {
        '& .MuiTypography-root': {
          fontFamily: "Open Sans, sans-serif"
        }
      }
    },
    MuiToolbar: {
      root: {
       paddingTop: "24px"
      }
    }
  }
});

export default theme;