import React, { useState } from "react";
import {
  Grid,
  IconButton,
  Typography
} from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay";
import DeleteIcon from "@material-ui/icons/Delete";
import { useQuery, useQueryClient } from "react-query";
import { useFirebase } from "../Firebase";
import { useError } from "../ErrorProvider";
import Table from "../StyledComponents/MaterialTable";
import Confirm from "../StyledComponents/Confirm";
import { LoadingBackground } from "../StyledComponents";

const Participants = ({ participants }) => {
  const firebase = useFirebase();
  const { showError } = useError();
  const queryClient = useQueryClient();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { data, error, isLoading } = useQuery(
    "CompanyUsers",
    firebase.getCompanyUsers
  );

  if (error) {
    showError(`Error retreiving users: ${JSON.stringify(error)}`);
  }

  const confirmDeleteUser = (id) => {
    setConfirmOpen(true);
    setDeleteId(id);
  }

  const deleteUser = () => {
    firebase
      .deleteUser({ account_id: deleteId })
      .then(() => {
        queryClient.invalidateQueries("CompanyUsers");
      })
      .catch((err) => {
        showError(err);
      })
      .finally(() => {
        setConfirmOpen(false);
      });
  };

  const cols = [{
    title: "Actions",
    field: "id",
    render: rowData => (
      <IconButton onClick={() => confirmDeleteUser(rowData.id)}>
        <DeleteIcon />
      </IconButton>
      )
  }, {
    title: "Name",
    field: "username",
    defaultSort: "asc"
  }, {
    title: "Email",
    field: "email"
  }, {
    title: "Status",
    field: "accountStatus"
  }, {
    title: "Joined Date",
    field: "joinedDate",
    render: rowData => rowData.joinedDate ? rowData.joinedDate : "-"
  }, {
    title: "Inactivated Date",
    field: "deletedDate",
    render: rowData => rowData.deletedDate ? rowData.deletedDate : "-"
  }]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2">Participants</Typography>
      </Grid>
      <Grid item xs={12}>
        <LoadingOverlay spinner active={isLoading}>
          <LoadingBackground>
            {!isLoading && 
              <Table
                columns={cols}
                data={data.data}
              />
            }
          </LoadingBackground>
        </LoadingOverlay>
        <Confirm open={confirmOpen} title="Delete Participant" confirmText="Delete" cancelAction={() => setConfirmOpen(false)} confirmAction={() => deleteUser()}>
          Are you sure you want to delete this participant? This action can't be undone.
        </Confirm>
      </Grid>
    </Grid>
  );
};

export default Participants;
