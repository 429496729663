import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CompanyLogo from '../CompanyLogo';

const useStyles = makeStyles( (theme) => ({
      header: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.primary,
        fontSize: '50px',
        margin: "16px 0 4px 0",
        "& img": {
          height: "75px"
        },
        [theme.breakpoints.down("sm")]: {
          marginTop: "16px",
          fontSize: "40px",
          "& img": {
            height: "50px"
          }
        },
        marginBottom: "48px"
      }
   })
);

const Header = () => {
	const classes = useStyles();
	return (
        <h1 className={classes.header}>
            <CompanyLogo size="sm" height="75"/> Smart Squirrel
        </h1> 
	);
}

export default Header;