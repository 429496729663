import React, { useState } from "react";
import {
  Container,
  Grid,
  Divider,
  Card,
  CardContent,
  CardActions,
  TextField,
  FormControl,
} from "@material-ui/core";
import Header from "../CompanyHeader";
import { useLocation, useHistory } from "react-router-dom";
import { withFirebase } from "../Firebase";
import { useError } from "../ErrorProvider";

import { ButtonGreen } from "../StyledComponents";
import utils from "../utils";
import * as ROUTES from "../../constants/routes";

const AuthManagmenet = withFirebase(({ firebase }) => {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  //search example: mode=resetPassword&oobCode=actioncode&apiKey=apikey&lang=en
  const { search } = useLocation();
  const history = useHistory();
  const { showError, showSuccess } = useError();
  const resetPassword = () => {
    let actionCode = new URLSearchParams(search).get("oobCode");
    firebase
      .handleResetPassword(actionCode, password1)
      .then((d) => {
        showSuccess("Password updated successfully.");
        history.replace(ROUTES.SIGNIN);
      })
      .catch((err) => {
        showError(err.message);
      });
  };

  const isInvalid =
    password1 !== password2 ||
    !utils.validatePassword(password1) ||
    !utils.validatePassword(password2);

  const password1Invalid = !utils.validatePassword(password1);
  const password2Invalid = !utils.validatePassword(password2);

  return (
    <Container fixed>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardContent style={{ marginBottom: "24px" }}>
              <Grid container>
                <FormControl fullWidth>
                  <TextField
                    label="New Password"
                    type="password"
                    placeholder="New Password"
                    name="PasswordOne"
                    variant="outlined"
                    value={password1}
                    onChange={(evt) => setPassword1(evt.target.value)}
                    style={{ marginBottom: "15px" }}
                    error={password1Invalid}
                  />
                  <TextField
                    label="Verify Password"
                    type="password"
                    placeholder="Verify Password"
                    name="PasswordTwo"
                    variant="outlined"
                    value={password2}
                    onChange={(evt) => setPassword2(evt.target.value)}
                    error={password2Invalid}
                    helperText="Password must be at least 8 characters long"
                  />
                </FormControl>
              </Grid>
            </CardContent>
            <Divider orientation="horizontal" variant="fullWidth" />
            <CardActions>
              <Grid container>
                <ButtonGreen
                  disabled={isInvalid}
                  onClick={(evt) => resetPassword()}
                  variant="contained"
                  fullWidth
                >
                  Submit
                </ButtonGreen>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
});

export default AuthManagmenet;
