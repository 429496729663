import React from "react";
import { Drawer, Hidden, AppBar, Toolbar, IconButton } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    drawerWidth: {
        width: "200px"
    }
}));

const Side = ({ children }) => {
    const styles = useStyles();
    return (
        <Drawer 
            variant="permanent" 
            anchor="left" 
            classes={{
                paper: styles.drawerWidth
            }}
        >
            {children}
        </Drawer>
    );
  };

const Top = ({children, setOpen, open}) => {
    const styles = useStyles();
    
    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton onClick={(evt) => setOpen(true)} color="inherit">
                    <MenuIcon />
                </IconButton>
                <h4>Smart Squirrel</h4>
                <Drawer
                    open={open}
                    anchor="left"
                    elevation={20}
                    onClose={() => setOpen(false)}
                    classes={{
                        paper: styles.drawerWidth
                    }}
                >
                    {children}
                </Drawer>
            </Toolbar>
        </AppBar>
    )
}

const Sidebar = props => {
    return (
        <>
            <Hidden mdUp>
                <Top {...props} />
            </Hidden>
            <Hidden smDown>
                <Side {...props} />
            </Hidden>
        </>
    )    
}
  
  export default Sidebar;