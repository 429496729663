import React, { useState } from "react";
import { 
  Box, 
  ListItem, 
  ListItemIcon, 
  ListItemText,
  Divider,
  Tooltip
} from "@material-ui/core";

import { Switch, Route } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

import { AuthUserContext, withAuthorization } from "../Session";
import Participants from "./Participants";
import Stats from "./Stats";
import Sidebar from "../Sidebar";
import QueryProvider from "./QueryProvider";
import Winners from "./Winners";
import { makeStyles } from "@material-ui/core/styles";

import SignOutButton from "../Signout";
import CompanyLogo from "../CompanyLogo";
import { ResponsiveClasses } from "../StyledComponents";

import {
  PeopleOutline as PeopleOutlineIcon,
  AccountCircleOutlined,
  SpeedOutlined as SpeedOutlinedIcon,
  EmojiEventsOutlined as EmojiEventsOutlinedIcon,
} from "@material-ui/icons";

const HRDashboard = (props) => (
  <AuthUserContext.Consumer>
    {(authUser) => <HRDashboardAuth authUser={authUser} {...props} />}
  </AuthUserContext.Consumer>
);

const useStyles = makeStyles((theme) => ({
  centerContent: {
    alignItems: "center",
    justifyContent: "center",
  },
  pullBottom: {
    position: "fixed",
    bottom: 0
  },
  bottomMargin: {
    marginBottom: "42px"
  }
}));

const HRDashboardAuth = ({ authUser, ...props}) => {
  const responsive = ResponsiveClasses();
  const classes = useStyles();
  const [selection, setSelection] = useState(ROUTES.HRDASHBOARDHOME);
  const [email, setEmail] = useState(authUser.email);
  const [open, setOpen] = useState(false);

  const onItemClick = (selectedKey, e) => {
    setSelection(selectedKey);
    props.history.push(selectedKey);
    e.preventDefault();
    setOpen(false);
  };
  return (
    <QueryProvider>
      <Sidebar authUser={authUser} setOpen={setOpen} open={open}>
        <ListItem className={classes.centerContent}>
          <Box margin="32px 0 42px">
            <CompanyLogo size="sm" height="100" />
          </Box>
        </ListItem>
        <Divider classes={{ root: classes.bottomMargin }} />
        <ListItem
          button
          key={ROUTES.HRDASHBOARDHOME}
          onClick={(e) => onItemClick(ROUTES.HRDASHBOARDHOME, e)}
          selected={selection === ROUTES.HRDASHBOARDHOME}
        >
          <ListItemIcon>
            <SpeedOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Stats</ListItemText>
        </ListItem>
        <ListItem
          button
          key={ROUTES.HRDASHBOARDPARTICIPANTS}
          onClick={(e) => onItemClick(ROUTES.HRDASHBOARDPARTICIPANTS, e)}
          selected={selection === ROUTES.HRDASHBOARDPARTICIPANTS}
        >
          <ListItemIcon>
            <PeopleOutlineIcon />
          </ListItemIcon>
          <ListItemText>Paticipants</ListItemText>
        </ListItem>
        <ListItem
          button
          key={ROUTES.HRDASHBOARDWINNERS}
          onClick={(e) => onItemClick(ROUTES.HRDASHBOARDWINNERS, e)}
          selected={selection === ROUTES.HRDASHBOARDWINNERS}
        >
          <ListItemIcon>
            <EmojiEventsOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Winners</ListItemText>
        </ListItem>
        <ListItem className={classes.pullBottom}>
          <ListItemIcon>
            <Tooltip title={email}>
              <AccountCircleOutlined />
            </Tooltip>
          </ListItemIcon>
          <ListItemText>
            <SignOutButton />
          </ListItemText>
        </ListItem>
      </Sidebar>
      <main className={responsive.mainContent}>
        <Switch>
          <Route path={ROUTES.HRDASHBOARDHOME} component={Stats} />
          <Route
            path={ROUTES.HRDASHBOARDPARTICIPANTS}
            component={Participants}
          />
          <Route path={ROUTES.HRDASHBOARDWINNERS} component={Winners} />
          <Route exact path={ROUTES.HRDASHBOARD} component={Stats} />
        </Switch>
      </main>
    </QueryProvider>
  );
};

const condition = (authUser) => {
  return (!!authUser.claims && !!authUser.claims.admin);
};

export default withAuthorization(condition, ROUTES.USERDASHBOARD)(HRDashboard);
