export const LANDING = "/";
export const HOME = "/home";
export const ABOUT = "/about";
export const SIGNUP = "/signup";
export const SIGNIN = "/signin";
export const USERDASHBOARD = "/UserDashboard";
export const DASHBOARDHOME = "/UserDashboard/Home";
export const DASHBOARDHELP = "/UserDashboard/Help";
export const DASHBOARDSETTINGS = "/UserDashboard/Settings";
export const ADMINDASHBOARD = "/AdminDashboard";
export const ADMINDASHBOARDHOME = "/AdminDashboard/Home";
export const BANKSIGNUP = "/BankSignUp";
export const OAUTH = "/OAuth";
export const ACCOUNTSELECT = "/AccountSelect";
export const TOS = "/TermsOfService";
export const PASSWORDRESET = "/PasswordReset";
export const AUTHMANAGEMENT = "/auth";
export const HRDASHBOARD = "/HRDashboard";
export const HRDASHBOARDHOME = "/HRDashboard/Home";
export const HRDASHBOARDPARTICIPANTS = "/HRDashboard/Participants";
export const HRDASHBOARDWINNERS = "/HRDashboard/Winners";
