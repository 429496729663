import React, { useEffect, useState, useCallback } from "react";

import {
  Grid,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { styled } from "@material-ui/core/styles";
import { withFirebase } from "../Firebase";
import AddCompanyDialog from "./AddCompany";
import DeleteCompany from "./DeleteModal";
import { useError } from "../ErrorProvider";
import Table from "../StyledComponents/MaterialTable";

const PaddedGrid = styled(Grid)({
  padding: "32px 16px 0 16px",
});

const CompanyTable = styled(Table)({
  width: "100%"
})

const AdminCompanies = withFirebase(({ firebase }) => {
  const [companies, setCompanies] = useState([]);
  const [search, setSearch] = useState("");
  const { showError } = useError();

  const handleClickSearch = () => {
    companies.filter((ele) => {
      let data = ele.data();
      for (let key in data) {
        if (data[key].indexOf(search) > -1) {
          return true;
        }
      }
      return ele.id.indexOf(search) > -1;
    });
  };

  const handleMouseDownSearch = (event) => {
    event.preventDefault();
  };

  const handleDeleteCompany = (companyCode) => {
    firebase
      .cleanupCompany({ companyCode })
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        showError(JSON.stringify(err));
      });
  };

  const fetchData = useCallback(async () => {
    let data = await firebase.companies().get();
    setCompanies(data.docs.map(companyDoc => {
      let company = companyDoc.data();
      return { ...company, id: companyDoc.id};
    }));
  }, [firebase]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const cols = [{
    title: "Actions",
    field: "",
    render: rowData => (
      <DeleteCompany
        companyCode={rowData.id}
        firebase={firebase}
        callback={() => {
          handleDeleteCompany(rowData.id);
        }}
      />
    )
  }, {
    title: "Name",
    field: "CompanyName"
  }, {
    title: "HR Email",
    field: "ContactEmail"
  }, {
    title: "Company Code",
    field: "id"
  }]

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <h1>Companies</h1>
      </Grid>
      <Grid item xs={12} spacing={0}>
        <Paper className="overflow-auto">
          <PaddedGrid container item xs={12} spacing={0}>
            <Grid item xs={6}>
              <TextField
                type="search"
                value={search}
                onChange={(evt) => setSearch(evt.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="search"
                        onClick={handleClickSearch}
                        onMouseDown={handleMouseDownSearch}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid xs={6} container item justifyItems="flex-end">
              <AddCompanyDialog />
            </Grid>
          </PaddedGrid>
          <Table
            columns={cols}
            data={companies}
          />
        </Paper>
      </Grid>
    </Grid>
  );
});

export default AdminCompanies;
