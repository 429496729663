import React from 'react';
//import { Container,Col,Row,Card } from 'react-bootstrap';
import { Grid, Card, CardContent, Container, Divider, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { SignUpForm } from '../Signup';
import { SignInLink } from '../Signin';
import CompanyHeader from '../CompanyHeader';

const useStyles = makeStyles( (theme) => ({
      root: {
        flexGrow: 1,
      },
      header: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.primary,
      },
      listheader: {
        color: theme.palette.text.primary
      },
      listinfo: {
        color: theme.palette.text.secondary,
        fontSize: '14px',
        fontFamily: 'Open Sans, sans-serif'
      },
      extraCardPadding: {
        padding: "24px 16px"
      },
      horizontalDivider: {
          margin: '4px 0 18px'
      },
      topMargin: {
          marginTop: "24px"
      },
      bottomMargin: {
          marginBottom: "18px"
      },
      signupForm: {
        ['@media (min-width: 960px)']: {
               flexBasis: "calc(75% - 33px)"
        }
      },
      subTitle: {
          marginTop: "-40px"
      }
   })
);


const Home = () =>  {
    const classes = useStyles();

    return (
        <Container fixed className={classes.root}>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12}>
                    <CompanyHeader />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" className={classes.subTitle}>
                        Let's Go! The squirrels are hungry.
                    </Typography>
                </Grid>
                <Grid item md={9}>
                    <Card>
                        <CardContent className={classes.extraCardPadding}>
                            <Grid container spacing={4}>
                                <Grid item md={3} className={classes.listheader}>
                                    <p>Why you'll love it?</p>
                                    <ul className={classes.listinfo}>
                                        <li><Typography variant="body2">More savings</Typography></li>
                                        <li><Typography variant="body2">Win Prizes</Typography></li>
                                        <li><Typography variant="body2">Beat coworkers</Typography></li>
                                    </ul>
                                </Grid>
                                <Hidden smDown>
                                    <Divider orientation="vertical" flexItem variant="middle" className={classes.divider} />
                                </Hidden>
                                <Hidden mdUp>
                                    <Grid item xs={12}>
                                        <Divider orientation="horizontal" className={classes.horizontalDivider} />
                                    </Grid>
                                </Hidden>
                                <Grid item className={classes.signupForm}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <h5 className={classes.bottomMargin}>Sign Up</h5>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SignUpForm></SignUpForm>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} className={`${classes.topMargin} text-center`}>
                    <SignInLink />
                </Grid>
                <Grid item xs={12} className={`${classes.topMargin} text-center`}>
                    <Typography variant="subtitle2">Emergency Savings Incentive Program</Typography>
                </Grid>
            </Grid>
        </Container>
    );
}


export default Home;