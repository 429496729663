//https://github.com/hadriengerard/spinning-wheel-game-react
import React from "react";
import prizewheel from "../../audio/prizewheel.mp3";

export default class Wheel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      audio: new Audio(prizewheel)
    };
    this.selectItem = this.selectItem.bind(this);
  }
  
  selectItem() {
    this.state.audio.play();
    setTimeout(() => {
      if (this.state.selectedItem === null) {
        const tempArray = this.props.items.map((x, i) => {
          if (x === (this.props.isWinner ? "Winner" : "")) return i;
        }).filter(function (el) {
          return el != null;
        });
        const selectedItem =
          tempArray[Math.floor(Math.random() * tempArray.length)];
        if (this.props.onSelectItem) {
          setTimeout(() => {
            this.props.onSelectItem(selectedItem);
          },6000)
        }
        this.setState({ selectedItem });
      } /*else {
        this.setState({ selectedItem: null });
        setTimeout(this.selectItem, 500);
      }*/
    }, 750);
    
  }

  render() {
    const { selectedItem } = this.state;
    const { items } = this.props;

    const wheelVars = {
      "--nb-item": items.length,
      "--selected-item": selectedItem,
    };
    const spinning = selectedItem !== null ? "spinning" : "";

    return (
      <div className="wheel-container">
        <div
          className={`wheel ${spinning}`}
          style={wheelVars}
          onClick={this.selectItem}
        >
          {items.map((item, index) => (
            <div
              className="wheel-item"
              key={index}
              style={{ "--item-nb": index }}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
