import React from "react";
import { AuthUserContext, withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";
import CompanyHeader from "../CompanyHeader";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Divider,
  Button,
  Radio,
} from "@material-ui/core";
import CurrencyFormat from "react-currency-format";
import LoadingOverlay from "react-loading-overlay";

import * as ROUTES from "../../constants/routes";

const AccountSelect = (props) => (
  <AuthUserContext.Consumer>
    {(authUser) => <Accounts authUser={authUser} {...props} />}
  </AuthUserContext.Consumer>
);

class Accounts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: [],
      selectedValue: "",
      loading: true,
    };
  }

  componentDidMount() {
    if (!!this.props.authUser) {
      this.props.firebase
        .accounts(this.props.authUser.uid)
        .get()
        .then((doc) => {
          this.setState({ accounts: doc.data(), loading: false });
        });
    }
  }

  onChange = (event) => {
    this.setState({ selectedValue: event.target.value });
  };

  onSubmit = () => {
    this.setState({ loading: true });
    this.props.firebase
      .setUserAccount({ account_id: this.state.selectedValue })
      .then((data) => {
        this.setState({ loading: false });
        this.props.history.replace(ROUTES.USERDASHBOARD);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <CompanyHeader></CompanyHeader>
        </Grid>
        <Grid item sm={8} lg={4}>
          <LoadingOverlay active={this.state.loading} spinner>
            <Card>
              <CardContent className="cardHeader">
                <h5>What account do you want to use?</h5>
                <p className="cardSubheader">
                  Don't worry you can always change this later.
                </p>
              </CardContent>
              {Object.keys(this.state.accounts).map((item, i, arr) => (
                <React.Fragment key={i}>
                  <CardContent className="accountItem">
                    <Grid container alignItems="center">
                      <Grid item xs={2}>
                        <Radio
                          checked={this.state.selectedValue === item}
                          onChange={this.onChange}
                          value={item}
                          name="radio-account"
                          inputProps={{
                            "aria-label": this.state.accounts[item].name,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <span>
                          {this.state.accounts[item].name} -{" "}
                          {this.state.accounts[item].mask}
                        </span>
                      </Grid>
                      <Grid item xs={4} className="cardRightJustify">
                        <CurrencyFormat
                          value={this.state.accounts[item].balances.current}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  {i === arr.length - 1 ? null : (
                    <Divider orientation="horizontal" variant="middle" />
                  )}
                </React.Fragment>
              ))}
              <CardActions>
                <Button
                  fullWidth={true}
                  className="signUpButton"
                  type="button"
                  variant="contained"
                  color="primary"
                  disabled={this.state.selectedValue === ""}
                  onClick={this.onSubmit}
                >
                  LET'S GO!
                </Button>
              </CardActions>
            </Card>
          </LoadingOverlay>
        </Grid>
      </Grid>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withFirebase(withAuthorization(condition)(AccountSelect));
