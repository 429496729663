import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, CardContent, Container, Typography, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from "../Firebase";
import CompanyHeader from '../CompanyHeader';


const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	header: {
		'font-size': '18px' 
	},
	terms: {
		'margin-left': '16px',
    maxHeight: '800px',
    overflow: 'auto'
	},
  TOSContainer: {
    maxHeight: "100%"
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <CardContent
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </CardContent>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TermsOfServiceBase = ({ firebase }) => {
	const classes = useStyles();
  const [terms, setTerms] = useState({ title: '', text: '' });
  const [privacy, setPrivacy ] = useState({ title: '', text: '' });
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    firebase.agreements().get().then((collection) => {
      collection.forEach((doc) => {
        let data = doc.data();
        if (data.name === "TOS"){
          setTerms({
            title: 'Terms and Conditions',
            text: data.text
          })
        } else if (data.name === "PrivacyPolicy") {
          setPrivacy({ 
            title: "Privacy Policy",
            text: data.text
          })
        }

      });
    }).catch((err) => {
      console.log(err);
    })
  }, [firebase]);

	return (
 	 <Container fixed className={classes.root}>
 	 	<Grid container direction="row" justify="center" alignItems="center">
 	 		<Grid item xs={12}>
                <CompanyHeader />
            </Grid>
            <Grid item xs={12} className={classes.TOSContainer}>
            	<Card>
                <Tabs value={value} onChange={handleChange} >
                  <Tab label="Terms of Service" {...a11yProps(0)} />
                  <Tab label="Privacy Policy" {...a11yProps(1)} />
                </Tabs>
            		<TabPanel index={0} value={value}>
            			<div className={classes.header}>
		            		{terms.title}
		            	</div>
		            	<div className={classes.terms}>
                  {terms.text.split('\\n').map((ele, i) => {
                    return (<Typography variant="body2" gutterBottom
                     key={i}>{ele}</Typography>)
                  })}
		            	</div>
            		</TabPanel>
                <TabPanel index={1} value={value}>
                  <div className={classes.header}>
                    {privacy.title}
                  </div>
                  <div className={classes.terms}>
                  {privacy.text.split('\\n').map((ele, i) => {
                    return (<Typography variant="body2" gutterBottom
                     key={i}>{ele}</Typography>)
                  })}
                  </div>
                </TabPanel>
            	</Card>
            </Grid>
 	 	</Grid>
 	 </Container>
	);
};

const TermsOfService = withFirebase(TermsOfServiceBase);

export default TermsOfService;