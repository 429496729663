import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay";
import { styled } from "@material-ui/core/styles";
import { useQuery } from "react-query";
import { useFirebase } from "../Firebase";
import { useError } from "../ErrorProvider";
import { CompanyCodeDisplay, LoadingBackground } from "../StyledComponents";

const StatValue = styled(CardContent)({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  padding: "36px 0",
  fontSize: "24px",
  color: "#1E4E77"
});

const StatLabel = styled(CardActions)({
  justifyContent: "center",
  padding: "36px 0",
  fontSize: "14px",
  color: "#585D5F",
});

const Stats = () => {
  const firebase = useFirebase();
  const { showError } = useError();

  const Stats = [
    { Title: "Next Weekly Lottery", key: "weeklyDrawingDate" },
    { Title: "Next Quarterly Lottery", key: "quarterlyDrawingDate" },
    { Title: "Total Participants", key: "participants" },
    { Title: "Total Weekly Qulifiers", key: "weeklyQualifiers" },
    { Title: "Total Quarterly Qualifiers", key: "quarterlyQualifiers" },
    { Title: "Total Weekly Winners", key: "weeklyWinners" },
    { Title: "Total Quarterly Winners", key: "quarterlyWinners" },
  ];

  const { data, error, isLoading } = useQuery(
    "CompanyStatistics",
    firebase.getCompanyStatistics
  );

  if (error) {
    showError(`Error retreiving users: ${JSON.stringify(error)}`);
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h2">Stats</Typography>
        </Grid>
        <Grid item xs={6}>
          <CompanyCodeDisplay>
            Company Code: {data?.data?.companyCode}
          </CompanyCodeDisplay>
        </Grid>
      </Grid>
      <LoadingOverlay active={isLoading} spinner>
        <LoadingBackground>
          <Grid container spacing={2}>
            {!isLoading &&
              Stats.map((stat, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Card>
                    <StatValue>{data.data[stat.key] || "-"}</StatValue>
                    <Divider orientation="horizontal" variant="fullWidth" />
                    <StatLabel>{stat.Title}</StatLabel>
                  </Card>
                </Grid>
            ))}
          </Grid>
        </LoadingBackground>
      </LoadingOverlay>
    </div>
  );
};

export default Stats;
