import React, { useState, useEffect, useCallback } from "react";
import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Divider,
  LinearProgress,
  Paper,
  IconButton,
  Button,
  Box,
  Container
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import UpdateIcon from "@material-ui/icons/Update";
/*import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown } from '@fortawesome/free-solid-svg-icons';*/
import CurrencyFormat from "react-currency-format";
import Moment from "react-moment";
import LoadingOverlay from "react-loading-overlay";
//import CompanyLogo from "../CompanyLogo";
import DashboardGame from "./DashboardGame";
import { useError } from "../ErrorProvider";
import SVGLogo from "../../images/sqrl-01.svg";
import * as ROUTES from "../../constants/routes";
import MonthSummary from "./MonthSummary";
import playSound from "../../audio/sonicID.m4a";
import { Overrides } from "../StyledComponents";

const DashboardHome = (props) => (
  <AuthUserContext.Consumer>
    {(authUser) => <DashboardHomeAuth authUser={authUser} {...props} />}
  </AuthUserContext.Consumer>
);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 10
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 10,
    backgroundColor: "#008F6D",
  }
}))(LinearProgress);

const PlayBanner = withStyles((theme) => ({
  root: {
    backgroundColor: "#1E4E77",
    color: "white",
    padding: "16px",
    // backgroundImage: `url(${SVGLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    marginBottom: "12px"
  },
}))(Paper);

const WhiteButton = withStyles(() => ({
  root: {
    color: "white",
    borderColor: "white",
  },
}))(Button);

const pageStyles = makeStyles((theme) => ({
  dashboardContainer: {
    marginBottom: "24px"
  },
}))

const audio = new Audio(playSound);

const DashboardHomeAuth = ({ authUser, firebase, history }) => {
  const oneDay = 1000 * 60 * 60 * 24;
  const oneHour = 1000 * 60 * 60;
  const styles = Overrides();
  const classes = pageStyles();

  const getMonthName = (month) => {
    return new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      new Date().setMonth(month)
    );
  };

  const getQuarterlyDrawing = (quarterStart) => {
    var today = new Date(); // just need a new date object here, today is not significant
    today.setMonth(quarterStart + 3); //set our month to the first month of the next quarter
    today.setDate(0); //set our day to the last day of the previous month, the end of the quarter

    return today;
  };

  const calcProgress = (start, current, goal) => {
    return Math.min(
      Math.max(Number.parseInt(((current - start) / (goal - start)) * 100), 0),
      100
    );
  };

  const playGame = () => {
    audio.play();
    setView("game");
    setWeeklyGameEligible(false);
  };

  const playQuarterlyGame = () => {
    audio.play();
    setView("quarterlyGame");
    setQuarterlyGameEligible(false);
  };

  const gamePlayed = () => {
    setLoading(true);
    setView("dashboard");
    setWeeklyGameEligible(false);
    firebase
      .userPlayedGame({ type: "weekly" })
      .catch((err) =>
        showError(
          `Error updating account status after game ${JSON.stringify(err)}`
        )
      )
      .finally((d) => {
        setLoading(false);
      });
  };

  const quarterlyGamePlayed = () => {
    setLoading(true);
    setView("dashboard");
    setQuarterlyGameEligible(false);
    firebase
      .userPlayedGame({ type: "quarterly" })
      .catch((err) =>
        showError(
          `Error updating account status after game ${JSON.stringify(err)}`
        )
      )
      .finally((d) => {
        setLoading(false);
      });
  };

  const { showError } = useError();
  const [view, setView] = useState("dashboard");
  const [user, setUser] = useState({
    name: "",
    WeeklyStartBalance: 0.0,
    WeeklyBalanceGoal: 0.0,
    WeeklyProgress: 0,
    CurrentBalance: 0.0,
    WeeklyLotteryDate: new Date(),
    QuarterlyLotteryDate: new Date(),
    MonthOneSaveGoals: 0,
    MonthTwoSaveGoals: 0,
    MonthThreeSaveGoals: 0,
    MonthOne: getMonthName(0),
    MonthTwo: getMonthName(1),
    MonthThree: getMonthName(2),
    AccountUpdate: new Date(),
  });
  const [loading, setLoading] = useState(true);
  const [weeklyGameEligible, setWeeklyGameEligible] = useState(false);
  const [weeklyWinner, setWeeklyWinner] = useState(false);
  const [quarterlyWinner, setQuarterlyWinner] = useState(false);
  const [quarterlyGameEligible, setQuarterlyGameEligible] = useState(false);

  const loadData = () => {
    if (!!authUser) {
      setLoading(true);
      Promise.allSettled([
        firebase
          .user(authUser.uid)
          .get()
          .then((val) => {
            let userData = val.data();
            let balances = userData.account.balances;

            setWeeklyGameEligible(userData.weeklyGameStatus === "eligible");
            setQuarterlyGameEligible(
              userData.quarterlyGameStatus === "eligible"
            );

            setUser((prevState) => ({
              ...prevState,
              name: userData.username,
              WeeklyStartBalance: balances.weeklyStartBalance,
              WeeklyBalanceGoal: balances.weeklyBalanceGoal,
              WeeklyRemaining: Math.max(
                balances.weeklyBalanceGoal - balances.currentBalance,
                0
              ),
              WeeklyProgress: calcProgress(
                balances.weeklyStartBalance,
                balances.currentBalance,
                balances.weeklyBalanceGoal
              ),
              CurrentBalance: balances.currentBalance,
              WeeklyLotteryDate: balances.weeklyLotteryDate,
              QuarterlyLotteryDate: getQuarterlyDrawing(
                userData.quarterStart ?? 0
              ),
              MonthOneSaveGoals: userData.monthOneSaveGoals ?? 0,
              MonthTwoSaveGoals: userData.monthTwoSaveGoals ?? 0,
              MonthThreeSaveGoals: userData.monthThreeSaveGoals ?? 0,
              MonthOne: getMonthName(userData.quarterStart ?? 0),
              MonthTwo: getMonthName((userData.quarterStart ?? 0) + 1),
              MonthThree: getMonthName((userData.quarterStart ?? 0) + 2),
              AccountUpdate: new Date(balances.insertedDate.seconds * 1000),
              AccountStatus: userData.accountStatus,
              QuarterStart: userData.quarterStart
            }));
          })
          .catch((err) => {
            showError(JSON.stringify(err));
          }),
        firebase
          .isUserWinner()
          .then((d) => {
            setWeeklyWinner(d.data.weeklyWinner);
            setQuarterlyWinner(d.data.quarterlyWinner);
          })
          .catch((err) => showError(JSON.stringify(err))),
      ]).finally(() => setLoading(false));
    }
  };

  const checkBalanceAge = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (!user.AccountUpdate || user.AccountUpdate < new Date() - oneDay) {
        updateUserBalance().then(() => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }, []);

  const checkAccountStatus = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (user.AccountStatus === "LoginExpired") {
        showError(
          "Bank account login information has expired. Pleases reauthenticate using the settings page."
        );
        history.push(ROUTES.DASHBOARDSETTINGS);
        reject();
      } else {
        resolve();
      }
    });
  }, []);

  const updateUserBalance = () => {
    return firebase
      .updateUserBalance()
      .then((d) => {
        setUser((prevState) => ({
          ...prevState,
          CurrentBalance: d.data,
          insertedDate: new Date(),
        }));
      })
      .catch((err) => {
        console.log(err);
        showError(`${err.code} : ${err.message}`);
      });
  };

  const refreshBalance = () => {
    setLoading(true);
    updateUserBalance().finally(() => {
      setLoading(false);
    });
  };

  useEffect(loadData, [authUser]);
  useEffect(() => {
    checkAccountStatus()
      .then(() => {
        return checkBalanceAge();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user, checkAccountStatus, checkBalanceAge]);

  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={2}
        className={classes.dashboardContainer}
      >
        <Grid item sm={6}>
          <h1>Hello {user.name}</h1>
        </Grid>
        <Grid item sm={6}>
          <Typography variant="body2" className={`${styles.italic} ${styles.textRight}`}>“Save like a champion today” - Steve Squirrel</Typography>
        </Grid>
      </Grid>
      <Container>
        {weeklyGameEligible && (
          <PlayBanner bg={SVGLogo}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={10}>
                <Typography variant="h4">Whooo Hooo, Let's Play the Weekly Sweepstakes!</Typography>
              </Grid>
              <Grid item xs={12} sm={2} className={styles.textRight}>
                <WhiteButton variant="outlined" onClick={playGame}>
                  PLAY
                </WhiteButton>
              </Grid>
              <Grid item xs={12} align="left">
                <Typography variant="body1">You've earned it.</Typography>
              </Grid>
            </Grid>
          </PlayBanner>
        )}
        {quarterlyGameEligible && (
          <PlayBanner style={{ backgroundColor: "#008F6D" }} bg={SVGLogo}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={10}>
                <Typography variant="h4">Whooo Hooo, Let's Play the Quarterly Sweepstakes!</Typography>
              </Grid>
              <Grid item xs={12} sm={2} className={styles.textRight}>
                <WhiteButton variant="outlined" onClick={playQuarterlyGame}>
                  PLAY
                </WhiteButton>
              </Grid>
              <Grid item xs={12} align="left">
                <Typography variant="body1">You've earned it.</Typography>
              </Grid>
            </Grid>
          </PlayBanner>
        )}
        {view === "dashboard" && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item sm={6}>
              <LoadingOverlay active={loading} spinner>
                <Card>
                  <CardContent>
                    <Grid
                      container
                      direction={"column"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      spacing={3}
                    >
                      <Grid container item xs={12}>
                        <Grid item xs={12} className="cardHeader">
                          <span>Weekly Squirrel Sweepstakes</span>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid item xs={12} className="cardCenterJustify">
                        <Typography variant="body2">
                            Save{" "}
                            <CurrencyFormat
                              value={user.WeeklyRemaining}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />{" "}
                            more this week to enter the sweepstakes!
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid item xs={12} style={{ fontSize: "24pt" }}>
                          <CurrencyFormat
                            value={user.CurrentBalance}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </Grid>
                        <Grid item xs={2} className="cardRightJustify"></Grid>
                        <Grid item xs={12}>
                          <BorderLinearProgress
                            variant="determinate"
                            value={user.WeeklyProgress}
                          />
                        </Grid>
                        <Grid item xs={11} className="mt-2">
                          <Typography variant="body2">
                            Current Balance
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            aria-label="refresh"
                            onClick={() => {
                              refreshBalance();
                            }}
                            disabled={
                              user.AccountUpdate > new Date(Date.now() - oneHour)
                            }
                          >
                            <UpdateIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12} alignItems={"center"}>
                        <Grid item xs={6}>
                          <Typography variant="body2">Starting Balance</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          className="weeklyCurrencies cardRightJustify"
                          style={{ color: "#000000" }}
                        >
                          <CurrencyFormat
                            value={user.WeeklyStartBalance}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider
                            orientation="horizontal"
                            variant="fullWidth"
                            style={{ width: "100%", margin: "5px 0" }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                        <Typography variant="body2">Balance Goal</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          className="cardRightJustify weeklyCurrencies"
                        >
                          <CurrencyFormat
                            value={user.WeeklyBalanceGoal}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider orientation="horizontal" variant="fullWidth" />
                  <CardActions>
                    <Box flexBasis="100%" textAlign="center" padding="24px 0">
                      <Typography variant="body2">
                        Weekly sweepstakes held every Sunday!
                      </Typography>
                    </Box>
                  </CardActions>
                </Card>
              </LoadingOverlay>
            </Grid>
            <Grid item sm={6}>
              <LoadingOverlay active={loading} spinner>
                <Card>
                  <CardContent>
                    <Grid
                      container
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems={"stretch"}
                      spacing={3}
                    >
                      <Grid container item xs={12}>
                        <Grid item xs={12} className="cardHeader">
                          <span>Quarterly Squirrel Sweepstakes</span>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid item xs={12} className="cardCenterJustify">
                          <Typography variant="body2">
                            Participate by qualifying to play the weekly sweepstakes in 2 of 3 months during the quarter.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12} spacing={2}>
                        <Grid item lg={4} xs={12}>
                          <MonthSummary
                            month={user.MonthOne}
                            numWeeks={user.MonthOneSaveGoals}
                            numericMonth={user.QuarterStart}
                          />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                          <MonthSummary
                            month={user.MonthTwo}
                            numWeeks={user.MonthTwoSaveGoals}
                            numericMonth={user.QuarterStart + 1}
                          />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                          <MonthSummary
                            month={user.MonthThree}
                            numWeeks={user.MonthThreeSaveGoals}
                            numericMonth={user.QuarterStart + 2}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider orientation="horizontal" variant="fullWidth" />
                  <CardActions>
                    <Box flexBasis="100%" textAlign="center" padding="24px 0">
                      <Typography variant="body2">
                        $100 Quarterly Sweepstakes held on the first Thursday of each quarter!
                      </Typography>
                    </Box>
                  </CardActions>
                </Card>
              </LoadingOverlay>
            </Grid>
          </Grid>
        )}
      </Container>
        {view === "game" && (
          <DashboardGame isWinner={weeklyWinner} callback={gamePlayed} />
        )}
        {view === "quarterlyGame" && (
          <DashboardGame
            isWinner={quarterlyWinner}
            callback={quarterlyGamePlayed}
          />
        )}
    </div>
  );
};

export default compose(withFirebase, withRouter)(DashboardHome);
