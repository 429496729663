import React, { useState } from "react";
import {
  Container,
  Card,
  CardContent,
  CardActions,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Header from "../CompanyHeader";
import { Link } from "react-router-dom";
import { useError } from "../ErrorProvider";
import { withFirebase } from "../Firebase";
import { ButtonGreen } from "../StyledComponents";

import * as ROUTES from "../../constants/routes";

export const PasswordResetPage = withFirebase(({ firebase }) => {
  const [email, setEmail] = useState("");
  const { showError, showSuccess } = useError();

  const sendReset = () => {
    firebase
      .doPasswordReset(email)
      .then(() => {
        showSuccess(
          `An email will be sent to ${email} with password reset instructions.`
        );
      })
      .catch((err) => {
        showError(JSON.stringify(err));
      });
  };

  return (
    <Container fixed>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item sm={12} md={6}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">Forgot Your Password?</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" style={{ color: "#707070" }}>
                    Enter you email address and we'll send you a link to reset
                    your password.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label="Email"
                    placeholder="Email"
                    value={email}
                    onChange={(evt) => setEmail(evt.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ButtonGreen
                    variant="contained"
                    fullWidth
                    onClick={sendReset}
                  >
                    RESET PASSWORD
                  </ButtonGreen>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Link to={ROUTES.SIGNIN} style={{ color: "#F37748" }}>
                    Back To Sign In
                  </Link>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
});

const PasswordResetLink = () => (
  <Link to={ROUTES.PASSWORDRESET}>Forgot your password?</Link>
);

export default PasswordResetLink;
