import React from "react";
import {
  Grid,
  Typography
} from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay";
import { useQuery } from "react-query";
import { useFirebase } from "../Firebase";
import { useError } from "../ErrorProvider";
import Table from "../StyledComponents/MaterialTable";
import { LoadingBackground } from "../StyledComponents";

const Winners = () => {
  const firebase = useFirebase();
  const { showError } = useError();

  const { data, error, isLoading } = useQuery(
    "CompanyWinners",
    firebase.getCompanyWinners
  );

  if (error) {
    showError(`Error retreiving users: ${JSON.stringify(error)}`);
  }

  const cols = [{
    title: "Name",
    field: "username"
  },{
    title: "Email",
    field: "email"
  },{
    title: "Type",
    field: "DrawingType"
  },{
    title: "Win Date",
    field: "drawingDate",
    type: "date",
    defaultSort: "desc"
  },{
    title: "Played Sweepstakes",
    field: "sweepstakesPlayed",
    render: rowData => rowData.sweepstakesPlayed ? "Yes" : "No"
  }]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2">Winners</Typography>
      </Grid>
      <Grid item xs={12}>
        <LoadingOverlay spinner active={isLoading}>
          <LoadingBackground>
            {!isLoading && 
              <Table
                columns={cols}
                data={data.data}
              />
            }
          </LoadingBackground>
        </LoadingOverlay>
      </Grid>
    </Grid>
  );
};

export default Winners;
