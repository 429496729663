import React from 'react';

import logo from '../../images/logo.png';
import logox2 from '../../images/logox2.png';
import logox3 from '../../images/logox3.png';


// valid sizes are currently sm, md, lg
const CompanyLogo = ({ size, height }) => {
	const logoHeight = height || "75";

	const getLogo = (sz) => {
		switch(sz) {
			case 'sm':
				return logo;
			case 'md':
				return logox2;
			case 'lg':
				return logox3;
			default:
				return logo;

		}
	};

	return (
		<img src={getLogo(size)} alt="Logo" height={logoHeight}/> 
	);

}

export default CompanyLogo;